import React from 'react';
import "./About.css";

class About extends React.Component {

    render() {
        return (
            <div className="container-about">
                <h3>About</h3>
                    <ul>
                        <li>business understanding</li>
                        <li>analytical</li>
                        <li>development</li>
                        <li>self-learner</li>
                        <li>sports</li>
                        <li>curious</li>
                        <li>travel</li>
                        <li>design, art</li>
                    </ul> 
            </div>
        );
    }
}
 
export default About;