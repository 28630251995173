import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./Trainer.css"
import Main from "../../util/Main"


const Trainer = (props) => {

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [mail, setMail] = useState("")
    const [signup, setSignup] = useState("trainer signup")


    const navigate = useNavigate();

    const load = () => {
        if (loading) {
            return <div className="loading">Loading&#8230;</div>
        }
    };

    const handleSub = (e) => {
        e.preventDefault();
        setLoading(true);
        //props.onSubmit()
        Main.formSubmit(name, mail, signup)
        .then(() => setLoading(false))
        .then(() => navigate("/sent"))
    }

    return (
        <div className="container-form-trainer">
            {load()}
            <form id="contact-form-trainer" onSubmit={handleSub}>
                <div className="container-trainer">
                    <p className="thetext">Get notified when the training and nutrition coaching goes live</p>
                </div>
                <div className="inputs">
                    <input className="name" required placeholder="Name" onChange={e => setName(e.target.value)}></input>
                    <input className="email" required type="email" placeholder="E-Mail" onChange={e => setMail(e.target.value)}></input>
                </div>
                    <div className="buttons">
                        <button className="button" type="submit" value="Submit">Sign me up</button>
                    </div>
            </form>
        </div>
    )
};

export default Trainer;
